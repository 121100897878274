import { IEvaluateCourseRatingItem } from '../models/type';
import Theme1 from '../assets/images/theme1.webp';
import Theme2 from '../assets/images/theme2.webp';
import Theme3 from '../assets/images/theme3.webp';
import Theme4 from '../assets/images/theme4.webp';
import Theme5 from '../assets/images/theme5.webp';
import Theme6 from '../assets/images/theme6.webp';

export const CoachMediaResourcesTitle = new Map([
  ['pdf', 'Documents PDF'],
  ['web_link', 'Liens web'],
  ['video_link', 'Liens vidéo'],
  ['image', 'Images'],
]);

export const Coach_classify_decode = new Map([
  ['EXEC', 'Executive Coach'],
  ['MNGR', 'Coach Senior'],
  ['CDP', 'Coach'],
  ['CLB', 'Coach en devenir'],
]);

export const LANGUGAGE_map = new Map([
  ['fr', 'French'],
  ['en', 'English'],
  ['es', 'Spanish'],
  ['de', 'German'],
]);

export const coacheeSessionStatusTranslation_map = new Map([
  ['done', 'done'],
  ['coachee_is_absent', 'You were absent'],
  ['cancelled_by_coach', 'cancelled_by_coach'],
  ['cancelled_by_coachee', 'Cancelled by you'],
  ['interrupted', 'interrupted'],
]);

export const domainName = window.location.origin;

export const mediaResourcesFormat = [
  {
    key: 'all',
    text: 'All',
    value: '',
  },
  {
    key: 'web_link',
    text: 'Web link',
    value: 'web_link',
  },
  {
    key: 'video_link',
    text: 'Video link',
    value: 'video_link',
  },
  {
    key: 'pdf',
    text: 'Pdf',
    value: 'pdf',
  },
  {
    key: 'image',
    text: 'Image',
    value: 'image',
  },
];

export const mediaResourcesInputFormat = [
  {
    key: 'image',
    text: 'Image',
    value: 'image',
  },
  {
    key: 'pdf',
    text: 'PDF',
    value: 'pdf',
  },
  {
    key: 'video_link',
    text: 'Video link',
    value: 'video_link',
  },
  {
    key: 'web_link',
    text: 'Web link',
    value: 'web_link',
  },
];

export const calendlyAPIURL = 'https://api.calendly.com/users/me';

export const maxUploadSize = 5000000; //5MB

export const courseEvaluationQuestionsCollection: Array<IEvaluateCourseRatingItem> = [
  {
    question: 'Because of your performance',
    maxRating: 5,
    name: 'operational_performance',
  },
  {
    question: 'Because of work life quality',
    maxRating: 5,
    name: 'work_life_quality',
  },
  {
    question: 'Because of professional relationship',
    maxRating: 5,
    name: 'professional_relationships',
  },
  {
    question: 'Because of your leadership',
    maxRating: 5,
    name: 'leadership',
  },
  {
    question: 'Because of personal organization',
    maxRating: 5,
    name: 'personal_organization',
  },
];

export const monthLastAnswerIndex = 12;

export const MONTH_LABEL = 'month';

export const YEAR_LABEL = 'year';

export const SESSION_PAGE_HAVE_OVERLAY = 'media';

export const multimediaResourceThemes = [
  {
    theme: 'theme1',
    passcodes: [
      'MB19',
      'MB3',
      'PS3',
      'PB9',
      'MS5',
      'CS5',
      'MB6',
      'PB11',
      'PB8',
      'MB2',
      'DB19',
      'MS2',
      'MS1',
      'DS5',
      'DS2',
      'DB6',
      'DS1',
      'PS6',
      'DB2',
      'DB3',
    ],
    image: Theme1,
  },
  {
    theme: 'theme2',
    passcodes: [
      'PB16',
      'MB15',
      'PB1',
      'CB3',
      'PB3',
      'CB1',
      'MB9',
      'CB6',
      'MB6',
      'MB5',
      'DB10',
      'DB9',
      'PB4',
      'MB10',
      'CB4',
      'MB13',
      'PB14',
      'CB5',
      'MB4',
      'MB16',
      'PB5',
      'MS5',
      'DS5',
      'DS6',
      'MB1',
      'DB13',
      'MS6',
      'DB1',
      'MS9',
      'DB17',
      'MB17',
      'MS11',
      'MS1',
      'DS9',
      'DB16',
      'DB8',
      'DB4',
      'PS8',
      'PB2',
      'MB8',
      'CB2',
      'PS7',
      'MB7',
      'DB7',
      'CS7',
      'CS1',
      'MS2',
      'MS8',
      'DB6',
      'DB5',
    ],
    image: Theme2,
  },
  { theme: 'theme3', passcodes: ['MS2', 'MS1', 'DS2', 'DS1', 'PB8', 'CS2', 'MB13', 'DB13'], image: Theme3 },
  {
    theme: 'theme4',
    passcodes: [
      'MS1',
      'MB6',
      'MS8',
      'DB16',
      'MS11',
      'MS7',
      'MS2',
      'DS2',
      'MB16',
      'DS7',
      'DS11',
      'CS7',
      'PB15',
      'MB13',
      'PB14',
      'MB14',
      'PS4',
      'PB4',
      'MB10',
      'MB4',
      'MS9',
      'DB10',
      'PB16',
      'MB15',
      'DB4',
      'DB15',
      'CB4',
      'PB1',
      'CB1',
      'MB7',
      'DS9',
      'DS6',
      'MS6',
      'DS1',
      'CB5',
      'PB5',
      'PS8',
      'DB14',
      'DS8',
      'MB2',
      'DB2',
      'PB9',
    ],
    image: Theme4,
  },
  {
    theme: 'theme5',
    passcodes: [
      'MS1',
      'MB6',
      'MB15',
      'MS8',
      'PS4',
      'PS6',
      'MS2',
      'MB5',
      'PB16',
      'PB10',
      'DS2',
      'PB11',
      'DB6',
      'DB5',
      'DB15',
    ],
    image: Theme5,
  },
  {
    theme: 'theme6',
    passcodes: [
      'MB5',
      'MB13',
      'MS7',
      'MB11',
      'MB6',
      'MS6',
      'PB12',
      'MS1',
      'PB11',
      'MS4',
      'PS6',
      'MB2',
      'DB14',
      'PB15',
      'MB14',
      'DB6',
      'DS1',
      'DB10',
      'MS5',
      'DS5',
      'DB13',
      'DS7',
      'MB10',
      'PB14',
      'PB4',
      'MS2',
      'DB11',
      'DB2',
      'MB4',
      'PS8',
      'MS9',
    ],
    image: Theme6,
  },
];
